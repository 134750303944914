import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../components/contact-info/contact-info";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import List from "../../components/custom-widgets/list";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

import showMktoForm from "../../helpers/showMktoForm";

const CommercialRealEstateProductsAndServices = () => {
  const title = "Commercial Real Estate Products & Services";
  const description =
    "WaFd Bank is your one-stop shop for commercial real estate financing of all kinds. From construction loans to investment property, we can help.";

  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-products-and-services/hero-commercial-real-estate-01102023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-products-and-services/hero-commercial-real-estate-01102023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-products-and-services/hero-commercial-real-estate-01102023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-products-and-services/hero-commercial-real-estate-01102023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-products-and-services/hero-commercial-real-estate-01102023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-products-and-services/hero-commercial-real-estate-01102023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-products-and-services/hero-commercial-real-estate-01102023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/commercial-real-estate-products-and-services"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-commercial-real-estate-01102023.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "commercial-real-estate-products-and-services-hero",
    ...getHeroImgVariables(imgData),
    altText: "Commercial real estate agent meeting businessman in a modern office building",
    chevronBgClass: "bg-dark-green-01",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Expand Your Portfolio with WaFd's Expertise"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Commercial Real Estate Products and Services"
    }
  ];

  const propertyTypesListData = {
    accountName: "property-types",
    items: [
      {
        id: 1,
        text: "Multi-family"
      },
      {
        id: 2,
        text: "Commercial office"
      },
      {
        id: 3,
        text: "Industrial and warehouse"
      },
      {
        id: 4,
        text: "Age-restricted and senior housing"
      },
      {
        id: 5,
        text: "Mixed-use"
      },
      {
        id: 6,
        text: "Residential real estate development and spec construction financing"
      },
      {
        id: 7,
        text: "Specialty financing includes self-storage, charter schools, medical offices and student housing"
      }
    ]
  };

  const expertBankersListItems = {
    accountName: "bankers-item",
    items: [
      {
        id: 1,
        text: "Creative solutions from short-term, variable-rate construction financing to long-term, fixed-rate, permanent loans"
      },
      {
        id: 2,
        text: "Several flexible options available including interest-only, no origination fees, and little to no prepayment penalty loans"
      },
      {
        id: 3,
        text: "Secured master lines of credit, borrowing bases and guidance lines available"
      },
      {
        id: 4,
        text: "Cash out refinance structures available"
      },
      {
        id: 5,
        text: "Knowledgeable CRE bankers with experience in a wide range of asset types and markets"
      },
      {
        id: 6,
        text: "Flexible recourse options available, subject to underwriting metrics and relationship considerations"
      }
    ]
  };

  const ContactCommercialCta = ({ id }) => {
    return (
      <button
        id={id}
        className="btn btn-primary w-100 w-sm-auto no-min-width"
        onClick={(e) => {
          e.preventDefault();
          showMktoForm(1067);
        }}
      >
        Contact Us
      </button>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Commercial Real Estate Products and Services</h1>
        <h3>
          Count on WaFd Bank for responsive, knowledgeable answers to your questions and fast, efficient turnaround.
          WaFd Bank has experienced bankers in all facets of commercial real estate finance with a wide range of
          expertise.
        </h3>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../images/thumbnail-commercial-real-estate-products-services-product-types-022824.jpg"
                alt="Man walking while holding laptop through empty commercial building hallway."
                placeholder="blurred"
                quality="100"
                className="border-radius-12"
              />
            </div>
            <div className="col-md-6">
              <h2 className="text-success font-weight-semi-bold">Property Types</h2>
              <List {...propertyTypesListData} />
              <ContactCommercialCta id="contact-commercial-banking-cta-1" />
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <h2 className="text-success font-weight-semi-bold">Our Expert Bankers Are Here to Assist You</h2>
            <List {...expertBankersListItems} />
            <ContactCommercialCta id="contact-commercial-banking-cta-2" />
          </div>
          <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">
            <StaticImage
              src="../../images/thumbnail-commercial-real-estate-products-services-expert-bankers-022824.jpg"
              alt="Businesswomen shaking hands while smiling."
              placeholder="blurred"
              quality="100"
              className="border-radius-12"
            />
          </div>
        </div>
      </section>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default CommercialRealEstateProductsAndServices;
